import { useState, useEffect, useContext } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import axios from "axios";
import VN from "../components/VN";
import { API_URL } from "../App";
import { UserDataContext } from "../components/UserDataContext";
import "../components/button.css";
import {
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import Alerts from "../components/Alerts";
import { marginBottom } from "../App";
import Button from "@mui/material/Button";

const PassChange = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [error, setError] = useState("");
  const { userData } = useContext(UserDataContext);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [showApiResponse, setShowApiResponse] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [apiResponse, setApiResponse] = useState({});
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      repeatNewPassword === newPassword &&
      newPassword.length > 5
      //&&   oldPassword !== newPassword
    ) {
      try {
        const response = await axios.post(
          API_URL + `/passchange/${userData.cookie}`,
          {
            userId: selectedId,
            oldpassword: oldPassword,
            newpassword: newPassword,
            repeatnewpassword: repeatNewPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setError(response.data.error_msg);
        //console.log(response.data);
      } catch (error) {
        setApiResponse({
          title: "Błąd",
          severity: "error",
          info: "Wystąpił błąd.",
        });
        console.error(error);
        setShowApiResponse(true);
      }
    } else {
      setApiResponse({
        title: "Błąd",
        severity: "error",
        info: "Hasła niezgodne lub zbyt krótkie",
      });
      setShowApiResponse(true);
      setNewPassword("");
      setRepeatNewPassword("");
      return;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = API_URL + `/groupsusers/${userData.cookie}/${userData.grpId}`;
      try {
        const response = await axios.get(url);

        setData(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

    if (userData && userData.userId && userData.grpId) {
      fetchData();
      const intervalId = setInterval(fetchData, 100000);
      return () => clearInterval(intervalId);
    }
  }, [userData]);

  useEffect(() => {
    if (selectedId && selectedId === userData.userId) {
      setShowOldPassword(true);
    } else {
      setShowOldPassword(false);
    }
  }, [selectedId]);
  useEffect(() => {
    if (error === 200) {
      setApiResponse({
        title: "Sukces",
        severity: "success",
        info: "Hasło zmienione pomyślnie",
      });
      setShowApiResponse(true);
    }
    if (error === 403) {
      setApiResponse({
        title: "Błąd",
        severity: "error",
        info: "Brak uprawnień do zmiany hasła.",
      });
      setShowApiResponse(true);
    }
  }, [error]);

  return (
    <>
      <VN userData={userData} />
      {showApiResponse && (
        <Row style={{ marginBottom }}>
          <Alerts data={apiResponse} />
        </Row>
      )}
      <Container>
        <Form>
          <Row style={{ marginBottom }}>
            <Col>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="selectUser">Wybierz użytkownika</InputLabel>
                <Select
                  labelId="selectUser"
                  id="selectUser"
                  label="Wybierz użytkownika"
                  value={selectedId || ""}
                  onChange={(e) => setSelectedId(e.target.value)}
                  fullWidth
                >
                  {data.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.fullName} [{user.user}]
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          </Row>

          {showOldPassword && (
            <Row style={{ marginBottom }}>
              <Col>
                <FormControl fullWidth>
                  <TextField
                    label="Dotychczasowe hasło"
                    type="password"
                    variant="outlined"
                    value={oldPassword || ""}
                    onChange={(e) => setOldPassword(e.target.value)}
                    fullWidth
                  />
                </FormControl>
              </Col>
            </Row>
          )}

          <Row style={{ marginBottom }}>
            <Col>
              <FormControl fullWidth>
                <TextField
                  label="Nowe hasło"
                  type="password"
                  variant="outlined"
                  value={newPassword || ""}
                  onChange={(e) => setNewPassword(e.target.value)}
                  fullWidth
                />
              </FormControl>
            </Col>
          </Row>

          <Row style={{ marginBottom }}>
            <Col>
              <FormControl fullWidth>
                <TextField
                  label="Powtórz nowe hasło"
                  type="password"
                  variant="outlined"
                  value={repeatNewPassword || ""}
                  onChange={(e) => setRepeatNewPassword(e.target.value)}
                  fullWidth
                />
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button variant="contained" onClick={handleSubmit}>
                Zmień hasło
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default PassChange;
