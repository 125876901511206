import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Row } from "react-bootstrap";
import CustomTabPanel from "./CustomTabPanel"; // Przenieś CustomTabPanel do osobnego pliku, jeśli to konieczne.
import Actors from "../pages/Actors";

export default function TabContent({ value, handleChange }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="underMenu"
          variant="scrollable"
          textColor="secondary"
          indicatorColor="secondary"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Aktorzy" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Row>
          <Actors />
        </Row>
      </CustomTabPanel>
    </Box>
  );
}
