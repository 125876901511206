import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { Table, Row, Col, Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../components/LandingPage.css";
import axios from "axios";
import VN from "../components/VN";
import { API_URL } from "../App";
import { cookie } from "../App";
import { UserDataContext } from "../components/UserDataContext";
import "../components/button.css";
import Alerts from "../components/Alerts";
import { TextField, FormControl } from "@mui/material";
import { marginBottom } from "../App";
import Button from "@mui/material/Button";
import film from "../images/film.png";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../components/theme";

const Login = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [apiResponse, setApiResponse] = useState({});
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState("");
  const [error, setError] = useState("");
  const { userData, setUserData } = useContext(UserDataContext);
  const [showApiResponse, setShowApiResponse] = useState(false);
  const ClearApiResponse = () => {
    setShowApiResponse(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        API_URL + "/login",
        {
          user: user,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setUserData(response.data);
      setError(response.data.error_msg);
      if (response.data.error_msg === 403) {
        setApiResponse({
          title: "Błąd",
          severity: "error",
          info: "Brak uprawnień lub niewłaściwe hasło!",
        });
        setShowApiResponse(true);
      }
      if (response.data.error_msg === 501) {
        setApiResponse({
          title: "Błąd",
          severity: "error",
          info: "Konto nieaktywne, zablokowane lub nie istnieje!",
        });
        setShowApiResponse(true);
      }
      if (response.data.error_msg === 502) {
        setApiResponse({
          title: "Ostrzeżenie",
          severity: "warning",
          info: "Nie podano nazwy użytkownika.",
        });
        setShowApiResponse(true);
      }
      if (response.data.error_msg === 503) {
        setApiResponse({
          title: "Ostrzeżenie",
          severity: "warning",
          info: "Nie podano hasła.",
        });
        setShowApiResponse(true);
      }
      Cookies.set(cookie, response.data.cookie, { expires: 1 });
    } catch (error) {
      setApiResponse({
        title: "Błąd",
        severity: "error",
        info: "Błąd podczas wysyłania danych.",
      });
      setShowApiResponse(true);
      navigate("/nopermission");
    }
  };

  useEffect(() => {
    if (userData && userData.userId > 0) {
      navigate("/mainpage/" + userData.userId);
    }
  }, [userData]);

  useEffect(() => {
    const value = Cookies.get(cookie);
    setSessionId(value);
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <>
      <VN />
      {showApiResponse && (
        <Row style={{ marginBottom }}>
          <Alerts data={apiResponse} />
        </Row>
      )}
      <Container>
        <Row>
          <Col>
            <h2>System obsługi produkcji filmowej</h2>
            <h1>MATOMI</h1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Form onKeyDown={handleKeyDown} autoComplete="off">
          <br></br>
          <Row style={{ marginBottom }}>
            <Col md={2}>
              <ThemeProvider theme={theme}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="none"
                    label="Konto użytkownika"
                    type="text"
                    variant="outlined"
                    value={user || ""}
                    onChange={(e) => {
                      setUser(e.target.value);
                      ClearApiResponse();
                    }}
                    fullWidth
                  />
                </FormControl>
              </ThemeProvider>
            </Col>
            <Col md={4}></Col>
            {/*
            <Col md={6}>
              Jeśli nie posiadasz konta - zarejestruj je tu:{" "}
              <a href="addgroupowner">Rejestracja konta</a>
            </Col>
            */}
          </Row>
          <Row style={{ marginBottom }}>
            <Col md={2}>
              <ThemeProvider theme={theme}>
                <FormControl fullWidth>
                  <TextField
                    label="Hasło"
                    type="password"
                    variant="outlined"
                    value={password || ""}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      ClearApiResponse();
                    }}
                    fullWidth
                  />
                </FormControl>
              </ThemeProvider>
            </Col>
            <Col md={4}></Col>
            {/*
            <Col md={6}>
              Jeśli nie pamiętasz hasła - kliknij tu:{" "}
              <a href="passresetmail">Reset hasła</a>
            </Col>
            */}
          </Row>
          <Row style={{ marginBottom }}>
            <Col>
              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Zaloguj
                </Button>
              </ThemeProvider>
            </Col>
            <Row style={{ marginBottom }}>
              <img src={film} alt="film" />
            </Row>
          </Row>
        </Form>
      </Container>
      <h6>Allworks sp. z o.o. </h6>
    </>
  );
};

export default Login;
