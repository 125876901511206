import { useState, useEffect, useContext } from "react";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import { Form, Row, Col, Modal } from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../App";
import { UserDataContext } from "../components/UserDataContext";
import { registerLocale } from "react-datepicker";
import { addDays, isAfter } from "date-fns";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import "../components/button.css";
import { TextField } from "@mui/material";
import useCountStore from "../components/store";
import Alerts from "../components/Alerts";
import { marginBottom } from "../App";
import Button from "@mui/material/Button";
import BlockSharpIcon from "@mui/icons-material/BlockSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Checkbox, FormControlLabel } from "@mui/material";
import { pl } from "date-fns/locale";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../components/theme";

const validationSchema = (idgrpAdmin) =>
  Yup.object().shape({
    user: Yup.string().required("Użytkownik jest wymagany"),
    fullName: Yup.string().required("Imię i nazwisko są wymagane"),
    mobile: Yup.string()
      .matches(/^\d+$/, "Telefon musi zawierać tylko cyfry")
      .required("Telefon jest wymagany"),
    selectedDate: Yup.date()
      .nullable()
      .required("Data jest wymagana")
      .test(
        "is-after-today",
        "Data musi być późniejsza niż dzisiaj",
        (value) => value && isAfter(value, new Date())
      ),
  });
registerLocale("pl", pl);

export default function EditUser({ user }) {
  const { id } = useParams();
  const { userData } = useContext(UserDataContext);
  const [error, setError] = useState();
  const [userUserData, setUserUserData] = useState({});
  const [checked, setChecked] = useState(false);
  //const [helper, setHelper] = useState(0);
  const [initialFormValues, setInitialFormValues] = useState({
    user: "",
    fullName: "",
    email: "",
    mobile: "",
    selectedDate: null,
    expire: "",
    is_active: "",
  });
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [deleted, setDeleted] = useState(null);
  const [buttonText, setButtonText] = useState();
  const [apiResponse, setApiResponse] = useState({});
  const [idgrpAdmin, setIdgrpAdmin] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setSelectedId(id);
    setShow(true);
  };
  const [selectedDate, setSelectedDate] = useState(null);
  const [showApiResponse, setShowApiResponse] = useState(false);

  const [timeOfDay, setTimeOfDay] = useState("");
  const handleChange = (event) => {
    setTimeOfDay(event.target.value);
  };
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };
  const ClearApiResponse = () => {
    setShowApiResponse(false);
  };
  const count = useCountStore((state) => state.count);
  const handleSubmit = async (values, { setSubmitting }) => {
    const formatted = format(values.selectedDate, "yyyy-MM-dd");
    values["idgrp"] = userData.idgrp;
    values["expire"] = formatted;
    values["id"] = id;
    try {
      const response = await axios.put(
        API_URL + `/edituser/${userData.cookie}/${id}`,
        values
      );
      setShowApiResponse(true);
      setApiResponse({
        title: "Informacja",
        severity: "info",
        info: "Dane zaktualizowane!",
      });
    } catch (error) {
      setShowApiResponse(true);
      setApiResponse({
        title: "Uwaga",
        severity: "error",
        info: "Nie udało się zaktualizować!",
      });
      console.error("There was an error editing the user!", error);
    } finally {
      setSubmitting(false);
    }
  };
  const handleDelete = async () => {
    try {
      const response = await axios.put(
        API_URL + `/updateuser/${userData.cookie}/${id}`,
        { id: selectedId, is_active: deleted },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setShow(false);
    } catch (error) {
      console.error("Błąd podczas próby zablokowania użytkownika:", error);
    }
  };
  const handleCheckbox = async (event) => {
    setChecked(event.target.checked);
    let helper;
    if (event.target.checked === true) {
      helper = 1;
    }
    if (event.target.checked === false) {
      helper = 0;
    }
    const url = API_URL + `/helper/${userData.cookie}/${id}`;

    try {
      const response = await axios.put(
        url,
        { helper: helper },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setShowApiResponse(true);
      setApiResponse({
        title: "Informacja",
        severity: "info",
        info: "Dane współagenta zaktualizowane!",
      });
    } catch (error) {
      console.error("Błąd podczas próby zablokowania użytkownika:", error);
    }
  };

  useEffect(() => {
    if (userData && userData.grpAdmin) {
      if (userData.grpAdmin !== "1") {
        navigate("/nopermission");
      }
    }
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${API_URL}/user/${userData.cookie}/${id}`;
      try {
        const response = await axios.get(url);
        if (response.data) {
          if (response.data[0].helper === "1") {
            setChecked(true);
          }

          setUserUserData(response.data);
          setIdgrpAdmin({ idgrpAdmin: response.data[0].idgrpAdmin });
          setInitialFormValues({
            user: response.data[0].user || "",
            fullName: response.data[0].userFullName || "",
            email: response.data[0].email || "",
            mobile: response.data[0].mobile || "",
            selectedDate: response.data[0].expire
              ? new Date(response.data[0].expire)
              : null,
            expire: response.data[0].expire || "",
            is_active: response.data[0].is_active || "",
          });
        }
      } catch (error) {
        setError(error.message);
      }
    };
    if (userData && userData.userId) {
      fetchData();
      const intervalId = setInterval(fetchData, 100000);
      return () => clearInterval(intervalId);
    }
  }, [userData, deleted]);

  useEffect(() => {
    if (
      initialFormValues.is_active === "0" &&
      initialFormValues.is_active !== ""
    ) {
      setButtonText("Przywróć dostęp");
      setDeleted(1);
    }
    if (
      initialFormValues.is_active === "1" &&
      initialFormValues.is_active !== ""
    ) {
      setButtonText("Zablokuj");
      setDeleted(0);
    }
  }, [initialFormValues]);

  return (
    <div>
      {showApiResponse && (
        <Row style={{ marginBottom }}>
          <Alerts data={apiResponse} />
        </Row>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Potwierdzenie</Modal.Title>
        </Modal.Header>
        <Modal.Body>Czy na pewno ?</Modal.Body>
        <Modal.Footer>
          <Button variant="outlined" onClick={handleClose}>
            Anuluj
          </Button>
          <Button variant="contained" onClick={handleDelete}>
            {deleted === 0 ? "Blokuj" : "Przywróć"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Row className="justify-content-md-center"></Row>
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, setValues, values, handleChange }) => (
          <FormikForm>
            <Row className="align-items-start">
              <Col md={5}>
                <Form.Group controlId="formUser">
                  <br></br>
                  <TextField
                    name="user"
                    label="Konto logowania użytkownika"
                    variant="outlined"
                    value={values.user}
                    onChange={(e) => {
                      handleChange(e);
                      ClearApiResponse();
                    }}
                    fullWidth
                    inputProps={{ readOnly: true }}
                  />
                </Form.Group>
              </Col>
              <Col md={5}>
                <Form.Group controlId="formFullName">
                  <br></br>
                  <TextField
                    name="fullName"
                    label="Imię i nazwisko użytkownika"
                    variant="outlined"
                    value={values.fullName}
                    onChange={(e) => {
                      handleChange(e);
                      ClearApiResponse();
                    }}
                    fullWidth
                  />
                  <ErrorMessage
                    name="fullName"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>
              <Col md={2} className="mt-4">
                {" "}
                {userUserData &&
                  initialFormValues.is_active !== "" &&
                  id !== userData.userId && (
                    <Button
                      variant="contained"
                      onClick={() => handleShow(user)}
                    >
                      <BlockSharpIcon />
                      {buttonText}
                    </Button>
                  )}
              </Col>
            </Row>

            <Row style={{ marginBottom }}>
              <Col md={6}>
                <br></br>
                <Form.Group controlId="formEmail">
                  <TextField
                    name="email"
                    label="E-mail"
                    variant="outlined"
                    value={values.email}
                    onChange={(e) => {
                      handleChange(e);
                      ClearApiResponse();
                    }}
                    fullWidth
                  />

                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formMobile">
                  <br></br>
                  <TextField
                    name="mobile"
                    label="Telefon"
                    variant="outlined"
                    value={values.mobile}
                    onChange={(e) => {
                      handleChange(e);
                      ClearApiResponse();
                    }}
                    fullWidth
                  />
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={6}>
                <Form.Group
                  controlId="formDate"
                  className="d-flex align-items-center"
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={pl}
                  >
                    <DatePicker
                      label="Wybierz datę ważności konta"
                      value={values.selectedDate}
                      onChange={(date) => {
                        setValues({ ...values, selectedDate: date });
                        ClearApiResponse();
                      }}
                      format="yyyy-MM-dd"
                      slotProps={{ textField: { variant: "outlined" } }}
                      minDate={addDays(new Date(), 1)}
                    />
                  </LocalizationProvider>
                  <ErrorMessage
                    name="selectedDate"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>

              <Col md={3} className="d-flex align-items-center">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  <EditSharpIcon />
                  Zapisz
                </Button>
              </Col>
              <Col md={3} className="d-flex align-items-center">
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleCheckbox} />
                  }
                  label="Współagent"
                />
              </Col>
            </Row>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
}
