import { Table, Row, Col } from "react-bootstrap";
import { API_URL } from "../App";
import { UserDataContext } from "../components/UserDataContext";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddAgent from "./AddAgent";
import "../components/button.css";
import Button from "@mui/material/Button";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { marginBottom } from "../App";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../components/theme";

export default function DataAgents({ group }) {
  const { userData } = useContext(UserDataContext);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const navigate = useNavigate();
  const [showRegister, setShowRegister] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  useEffect(() => {
    const fetchData = async () => {
      const url = API_URL + `/groupsusers/${userData.cookie}/${group.id}`;
      try {
        const response = await axios.get(url);
        //console.log(response.data);
        setData(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

    if (userData && userData.userId) {
      fetchData();
      const intervalId = setInterval(fetchData, 1000);
      return () => clearInterval(intervalId);
    }
  }, [userData, group]);

  const handleEditUser = (id) => {
    setSelectedId(id);
    setShowRegister(false);
    navigate(`/edittabs/${id}`);
  };
  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.key === null) return 0;
    const direction = sortConfig.direction === "asc" ? 1 : -1;

    if (a[sortConfig.key] < b[sortConfig.key]) {
      return -1 * direction;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return 1 * direction;
    }
    return 0;
  });
  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleAddAgent = () => {
    setShowRegister(!showRegister);
    setShowEdit(false);
    setSelectedId(0);
  };

  return (
    <div>
      <Row style={{ marginBottom }}>
        <Col xs={4} sm={3}>
          <ThemeProvider theme={theme}>
            <Button variant="outlined" onClick={handleAddAgent}>
              <SupportAgentIcon /> Dodaj Agenta
            </Button>
          </ThemeProvider>
        </Col>
        <Col xs={4} sm={3}>
          <h2>Lista Agentów</h2>
        </Col>
      </Row>
      <div className="table-responsive">
        <Table bordered hover>
          <thead>
            <tr>
              <th className="td-centered" onClick={() => requestSort("id")}>
                ID
              </th>
              <th onClick={() => requestSort("fullName")}>Agent</th>
              <th onClick={() => requestSort("email")}>Email</th>
              <th onClick={() => requestSort("fullName")}>Telefon</th>
              <th onClick={() => requestSort("fullName")}>Ważność konta</th>
              <th className="td-centered">Edytuj</th>
            </tr>
          </thead>

          <tbody>
            {sortedData.map((item, index) => (
              <tr key={index}>
                <td className="td-centered">{index + 1}</td>
                <td>
                  {item.fullName} [{item.id}]
                </td>
                <td>{item.email}</td>
                <td className="td-centered">{item.mobile}</td>
                <td>{item.expire}</td>
                <td className="td-centered">
                  {item.is_active === "1" ? (
                    <ThemeProvider theme={theme}>
                      <Button
                        variant="outlined"
                        onClick={() => handleEditUser(item.id)}
                      >
                        <i className="fa fa-edit" aria-hidden="true"></i>
                      </Button>
                    </ThemeProvider>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => handleEditUser(item.id)}
                    >
                      <i className="fa fa-ban" aria-hidden="true"></i>
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {showRegister && <AddAgent group={group.id} />}
    </div>
  );
}
