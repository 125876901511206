import { useState, useEffect, useCallback } from "react";
import { Formik, Form as FormikForm } from "formik";
import { Row, Col, Container } from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import "../components/button.css";
import { API_URL } from "../App";
import { TextField, Button } from "@mui/material";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Nieprawidłowy adres email")
    .required("Email jest wymagany"),
});

export default function PassResetMail({ group }) {
  const [error, setError] = useState();
  const [errorText, setErrorText] = useState("");
  const [verification, setVerification] = useState(null);
  const [email, setEmail] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [text, setText] = useState("");
  const [sent, setSent] = useState(true);
  const [cnt, setCnt] = useState(0);

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setEmail(values.email);
    if (cnt < 1) {
      try {
        const response = await axios.post(
          API_URL + "/registergroupowner",
          values
        );
        console.log("RD: ", response.data);
        setError(response.data.error);
        setVerification(response.data.token);
        setError(response.data.error);
      } catch (error) {
        console.error("Błąd podczas rejestracji!", error);
      } finally {
        setSubmitting(false);
      }
      setCnt(cnt + 1);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = API_URL + `/passresetmail`;
      try {
        const response = await axios.post(
          url,
          { email: email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.email === "1") {
          setReadOnly(false);
          setText("Email zweryfikowany poprawnie!");
        } else {
          setReadOnly(true);
          setText("Brak adresu email na liście uprawnionych");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData();
  }, [email, error]);

  useEffect(() => {
    if (error === 556) {
      setErrorText("Taki email już istnieje");
    }
    if (error === 555) {
      setErrorText("Taki user już istnieje");
    }
    if (error === 557) {
      setErrorText("Taka grupa już istnieje");
    }
    if (error === 200) {
      setSent(false);
      setErrorText(
        "Dane przekazane do zapisu. Poczekaj na mail z nowym hasłem i potwierdzeniem zmiany."
      );
    }
  }, [verification, error]);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={12}>
          <h2>Ustawienie nowego hasła</h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
            }) => (
              <FormikForm>
                <TextField
                  autoComplete="off"
                  label="Email użytkownika"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={(e) => {
                    handleChange(e);
                    setEmail(e.target.value);
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  margin="normal"
                />
                {text}
                <br></br>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Wyślij link z potwierdzeniem
                </Button>
              </FormikForm>
            )}
          </Formik>
        </Col>
        {errorText && <p className="text-danger">{errorText}</p>}
      </Row>
    </Container>
  );
}
