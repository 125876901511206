import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DataActors from "./DataActors";
import { API_URL } from "../App";
import axios from "axios";
import { UserDataContext } from "../components/UserDataContext";

export default function NoPermission() {
  const { userData } = useContext(UserDataContext);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [admin, setAdmin] = useState(0);
  const navigate = useNavigate();
  const [groupName, setGroupName] = useState({});

  useEffect(() => {
    setGroupName({
      id: userData.grpId,
      grpName: userData.grpName,
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const url = API_URL + `/groups/${userData.cookie}`;
      try {
        const response = await axios.get(url);
        setData(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

    if (userData && userData.userId) {
      fetchData();
      if (userData.grpAdmin === 0) {
        navigate("/nopermission");
      }
      setAdmin(userData.admin);
      const intervalId = setInterval(fetchData, 10000);
      return () => clearInterval(intervalId);
    }
  }, [userData, navigate]);

  const handleUpdate = (id) => {
    setData((prevData) => prevData.filter((item) => item.id !== id));
  };

  return (
    <div className="bodyReminder">
      {userData && userData.userId > 0 && <DataActors group={groupName} />}
    </div>
  );
}
