import { useState, useEffect, useContext } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { Form, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../App";
import { UserDataContext } from "../components/UserDataContext";
import "../components/button.css";
import { TextField } from "@mui/material";
import { marginBottom } from "../App";
import Alerts from "../components/Alerts";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../components/theme";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Imię i nazwisko aktora jest wymagane"),
  filmpolski: Yup.string()
    .required("Strona filmpolski.pl z osobą aktora jest wymagana")
    .matches("https://filmpolski.pl/", "Strona musi pochodzić z filmpolski.pl"),
});

export default function AddActor({ group }) {
  const { userData } = useContext(UserDataContext);
  const [error, setError] = useState();
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState({});
  const [showApiResponse, setShowApiResponse] = useState(false);
  const ClearApiResponse = () => {
    setShowApiResponse(false);
  };
  const initialValues = {
    name: "",
    filmpolski: "",
    email: "",
    phone: "",
    description: "",
    createdBy: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    values["idgrp"] = group;
    values["createdBy"] = userData.userId;
    //  console.log("Przekaz: ", values);

    try {
      const response = await axios.post(
        API_URL + `/registeractor/${userData.cookie}`,
        values
      );
      setError(response.data.error_msg);
      if (response.data.error_msg === 200) {
        setShowApiResponse(true);
        setApiResponse({
          title: "Sukces",
          severity: "success",
          info: "Aktor dodany!",
        });
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (userData && userData.grpAdmin) {
      if (userData.grpAdmin !== userData.userId) {
        //navigate("/nopermission");
      }
    }
    if (userData == undefined) {
      //navigate("/nopermission");
    }
  }, [userData]);

  useEffect(() => {
    if (error === 501) {
      setErrorText(
        "Taki aktor na podstawie weryfikacji filmpolski.pl już istnieje w bazie"
      );
    }
    if (error === 200) {
      setErrorText("Dane aktora przekazane do zapisu");
      navigate(0);
    }
  }, [error]);

  /*
  useEffect(() => {
    const fetchData = async () => {
      const url = API_URL + `/users/${userData.cookie}`;
      try {
        const response = await axios.get(url);
        setData(response.data);
        //const userWithId = response.data.find(
        //  (userData) => userData.id === user
        //);
        //setUserUserData(userWithId);
      } catch (error) {
        setError(error.message);
      }
    };

    if (userData && userData.userId) {
      fetchData();
      const intervalId = setInterval(fetchData, 100000);
      return () => clearInterval(intervalId);
    }
  }, [userData]);
*/
  return (
    <div>
      {showApiResponse && (
        <Row style={{ marginBottom }}>
          <Alerts data={apiResponse} />
        </Row>
      )}
      <h2>Rejestracja Aktora</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <FormikForm>
            <Row
              style={{ marginBottom: "16px" }}
              className="align-items-center"
            >
              <Col md="4" sm="12">
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formUser">
                    <Field
                      as={TextField}
                      label="Imię i nazwisko Aktora"
                      variant="outlined"
                      name="name"
                      autoComplete="off"
                      fullWidth
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
              <Col md="4" sm="12">
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formPassword">
                    <Field
                      as={TextField}
                      label="filmpolski.pl - link"
                      variant="outlined"
                      name="filmpolski"
                      fullWidth
                    />
                    <ErrorMessage
                      name="filmpolski"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
              <Col md="4" sm="12">
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formPhone">
                    <Field
                      as={TextField}
                      label="Telefon"
                      type="text"
                      name="phone"
                      fullWidth
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
            </Row>

            <Row style={{ marginBottom }}>
              <Col md="4" sm="12">
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formEmail">
                    <Field
                      as={TextField}
                      label="Email"
                      type="email"
                      name="email"
                      fullWidth
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
              <Col md="4" sm="12">
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formDescription">
                    <Field
                      as={TextField}
                      label="Informacje dodatkowe"
                      type="text"
                      name="description"
                      fullWidth
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
            </Row>

            <Row style={{ marginBottom }}>
              <Col>
                <ThemeProvider theme={theme}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Rejestruj
                  </Button>
                </ThemeProvider>
              </Col>
            </Row>
          </FormikForm>
        )}
      </Formik>

      {errorText}
    </div>
  );
}
