import { useState, useEffect, useCallback } from "react";
import { Formik, Form as FormikForm } from "formik";
import { Row, Col, Container } from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import "../components/button.css";
import { API_URL } from "../App";
import { TextField, Button } from "@mui/material";

const validationSchema = Yup.object().shape({
  groupname: Yup.string().required("Nazwa grupy jest wymagana"),
  user: Yup.string().required("Użytkownik jest wymagany"),
  password: Yup.string()
    .min(8, "Hasło musi mieć co najmniej 8 znaków")
    .matches(/[0-9]/, "Hasło musi zawierać co najmniej jedną cyfrę")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Hasło musi zawierać co najmniej jeden znak specjalny"
    )
    .required("Hasło jest wymagane"),
  repeatpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Hasła muszą być takie same")
    .required("Potwierdzenie hasła jest wymagane"),
  fullName: Yup.string().required("Imię i nazwisko są wymagane"),
  email: Yup.string()
    .email("Nieprawidłowy adres email")
    .required("Email jest wymagany"),
  mobile: Yup.string()
    .matches(/^\d+$/, "Telefon musi zawierać tylko cyfry")
    .required("Telefon jest wymagany"),
});

export default function AddGroupOwner({ group }) {
  const [error, setError] = useState();
  const [errorText, setErrorText] = useState("");
  const [verification, setVerification] = useState(null);
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [text, setText] = useState("");
  const [textUser, setTextUser] = useState("");
  const [sent, setSent] = useState(true);
  const [cnt, setCnt] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const [buttonMail, setButtonMail] = useState(0);
  const [buttonUser, setButtonUser] = useState(0);
  const [mailVeryf, setMailVeryf] = useState(0);

  const initialValues = {
    groupname: "",
    user: "",
    fullName: "",
    email: "",
    mobile: "",
    password: "",
    repeatpassword: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setEmail(values.email);
    setUser(values.user);
    if (cnt < 1) {
      try {
        const response = await axios.post(
          API_URL + "/registergroupowner",
          values
        );
        setError(response.data.error);
        setVerification(response.data.token);
        setError(response.data.error);
      } catch (error) {
        console.error("Błąd podczas rejestracji!", error);
      } finally {
        setSubmitting(false);
      }
      setCnt(cnt + 1);
    }
  };

  const reggroupowner = useCallback(() => {
    if (sent) {
      //setShowButton(false);
      fetch(
        `http://185.180.205.154/mastermovie/src/registermail.php?token=${verification}&email=${email}`
      )
        .then((response) => response.text())
        .catch((error) => console.error("Błąd:", error));
    }
  }, [sent, verification, email]);

  useEffect(() => {
    const fetchData = async () => {
      const url = API_URL + `/checkowner`;
      try {
        const response = await axios.post(
          url,
          { email: email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.email === "1") {
          setReadOnly(false);
          setButtonMail(0);
          setText("Email zweryfikowany poprawnie!");
        } else if (response.data.email === "2") {
          setReadOnly(true);
          setText("Ten adres mailowy został już zarejestrowany ");
          setButtonMail(buttonMail + 1);
        } else {
          setReadOnly(true);
          setText("Brak adresu email na liście uprawnionych");
          setButtonMail(buttonMail + 1);
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData();
  }, [email, error]);

  useEffect(() => {
    //console.log("BUTTON: ", buttonMail, buttonUser);
    if (buttonMail + buttonUser === 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [buttonMail, buttonUser]);
  useEffect(() => {
    const fetchData = async () => {
      const url = API_URL + `/checkuser`;
      try {
        const response = await axios.post(
          url,
          { user: user },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.user === "1") {
          setTextUser("To konto już istnieje!  Zmień nazwę konta.");
          setButtonUser(buttonUser + 1);
        } else {
          setButtonUser(0);
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData();
  }, [user, error]);

  useEffect(() => {
    if (error === 556) {
      setErrorText("Taki email już istnieje");
    }
    if (error === 555) {
      setErrorText("Takie konto już istnieje!");
    }
    if (error === 557) {
      setErrorText("Taka grupa już istnieje");
    }
    if (error === 200) {
      setSent(false);
      setShowButton(false);
      setErrorText(
        "Dane przekazane do zapisu. Poczekaj na mail z linkiem aktywacyjnym."
      );
    }
    if (verification) {
      reggroupowner();
    }
  }, [verification, error, reggroupowner]);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={12}>
          <h2>Rejestracja administratora grupy Agentów {readOnly}</h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
            }) => (
              <FormikForm>
                <TextField
                  autoComplete="off"
                  label="Email osoby rejestrującej"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={(e) => {
                    handleChange(e);
                    setEmail(e.target.value);
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  margin="normal"
                />
                {text}
                <TextField
                  autoComplete="off"
                  label="Nazwa Grupy Agentów"
                  name="groupname"
                  type="text"
                  value={values.groupname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.groupname && Boolean(errors.groupname)}
                  helperText={touched.groupname && errors.groupname}
                  margin="normal"
                  InputProps={{ readOnly }}
                />
                <TextField
                  label="Konto logowania"
                  name="user"
                  type="text"
                  value={values.user}
                  onChange={(e) => {
                    handleChange(e);
                    setUser(e.target.value);
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.user && Boolean(errors.user)}
                  helperText={touched.user && errors.user}
                  margin="normal"
                  InputProps={{ readOnly }}
                />
                {textUser}
                <TextField
                  label="Imię i nazwisko"
                  name="fullName"
                  type="text"
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.fullName && Boolean(errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  margin="normal"
                  InputProps={{ readOnly }}
                />
                <TextField
                  label="Hasło"
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  margin="normal"
                  InputProps={{ readOnly }}
                />
                <TextField
                  label="Powtórz hasło"
                  name="repeatpassword"
                  type="password"
                  value={values.repeatpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={
                    touched.repeatpassword && Boolean(errors.repeatpassword)
                  }
                  helperText={touched.repeatpassword && errors.repeatpassword}
                  margin="normal"
                  InputProps={{ readOnly }}
                />
                <TextField
                  label="Telefon"
                  name="mobile"
                  type="text"
                  value={values.mobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.mobile && Boolean(errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                  margin="normal"
                  InputProps={{ readOnly }}
                />
                {showButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    //onClick={setShowButton(false)}
                  >
                    Rejestruj
                  </Button>
                )}
              </FormikForm>
            )}
          </Formik>
        </Col>
        {errorText && <p className="text-danger">{errorText}</p>}
      </Row>
    </Container>
  );
}
