import React from "react";
import { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Row } from "react-bootstrap";
import Agents from "../pages/Agents";
import AgentsLog from "../pages/AgentsLog";
import Actors from "../pages/Actors";
import Calendar from "../pages/Calendar";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../components/theme";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function TabContent() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("selectedTab", newValue);
  };
  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab) {
      setValue(parseInt(savedTab, 10));
    }
  }, []);
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <ThemeProvider theme={theme}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="underMenu"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Agenci" />
            <Tab label="Aktorzy" />
            <Tab label="Kalendarz porównawczy" />
            <Tab label="Logowania agentów" />
          </Tabs>
        </ThemeProvider>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Row>
          <Agents />
        </Row>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Row>
          <Actors />
        </Row>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Row>
          <Calendar data="all" />
        </Row>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Row>
          <AgentsLog />
        </Row>
      </CustomTabPanel>
    </Box>
  );
}
