import { Table, Row, Col } from "react-bootstrap";
import { API_URL } from "../App";
import { UserDataContext } from "../components/UserDataContext";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import "../components/button.css";
import { marginBottom } from "../App";
import Pagination from "@mui/material/Pagination";

export default function DataGroupUsers({ group }) {
  const { userData } = useContext(UserDataContext);
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      const url = `${API_URL}/getloginagents/${userData.cookie}`;
      try {
        const response = await axios.get(url);
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (userData && userData.userId) {
      fetchData();
      const intervalId = setInterval(fetchData, 1000);
      return () => clearInterval(intervalId);
    }
  }, [userData, group]);

  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.key === null) return 0;
    const direction = sortConfig.direction === "asc" ? 1 : -1;

    if (a[sortConfig.key] < b[sortConfig.key]) {
      return -1 * direction;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return 1 * direction;
    }
    return 0;
  });

  const paginatedData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div>
      <Row style={{ marginBottom }}>
        <Col xs={4} sm={2}></Col>
        <Col xs={8} sm={6}>
          <h2>Logowania Agentów</h2>
        </Col>
      </Row>
      <Row style={{ marginBottom }}>
        <Pagination
          count={Math.ceil(data.length / itemsPerPage)}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      </Row>
      <div className="table-responsive" id="printable-table">
        <Table bordered hover>
          <thead>
            <tr>
              <th className="td-centered" onClick={() => requestSort("id")}>
                ID
              </th>
              <th onClick={() => requestSort("fullName")}>Agent</th>
              <th onClick={() => requestSort("email")}>Data</th>
              <th onClick={() => requestSort("ip")}>IP</th>
            </tr>
          </thead>

          <tbody>
            {paginatedData.map((item) => (
              <tr key={item.id}>
                <td className="td-centered">{item.id}</td>
                <td>{item.fullName}</td>
                <td>{item.time}</td>
                <td className="td-centered">{item.ip}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Row style={{ marginBottom }}>
        <Pagination
          count={Math.ceil(data.length / itemsPerPage)}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      </Row>
    </div>
  );
}
