import { useState, useEffect, useContext } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { Form, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../App";
import { UserDataContext } from "../components/UserDataContext";
import { addDays, isAfter } from "date-fns";
import { format } from "date-fns";
import "../components/button.css";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { pl } from "date-fns/locale";
import { marginBottom } from "../App";
import Alerts from "../components/Alerts";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../components/theme";

const validationSchema = Yup.object().shape({
  user: Yup.string().required("Użytkownik jest wymagany"),
  password: Yup.string()
    .min(6, "Hasło musi mieć co najmniej 6 znaków")
    .required("Hasło jest wymagane"),
  repeatpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Hasła muszą być takie same")
    .required("Potwierdzenie hasła jest wymagane"),
  fullName: Yup.string().required("Imię i nazwisko są wymagane"),
  mobile: Yup.string()
    .matches(/^\d+$/, "Telefon musi zawierać tylko cyfry")
    .required("Telefon jest wymagany"),
  selectedDate: Yup.date()
    .nullable()
    .required("Data jest wymagana")
    .test(
      "is-after-today",
      "Data musi być późniejsza niż dzisiaj",
      (value) => value && isAfter(value, new Date())
    ),
});

export default function AddAgent({ group }) {
  const { userData } = useContext(UserDataContext);
  const [error, setError] = useState();
  const [errorText, setErrorText] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState({});
  const [showApiResponse, setShowApiResponse] = useState(false);
  const ClearApiResponse = () => {
    setShowApiResponse(false);
  };
  const initialValues = {
    user: "",
    password: "",
    repeatpassword: "",
    fullName: "",
    email: "",
    mobile: "",
    selectedDate: null,
    expire: "",
    agent: 0,
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const formatted = format(values.selectedDate, "yyyy-MM-dd");
    values["idgrp"] = group;
    values["expire"] = formatted;
    values["agent"] = 1;
    //console.log("Przekaz: ", values);

    try {
      //console.log("VALUES: ", values);
      const response = await axios.post(API_URL + "/register", values);

      setError(response.data.error_msg);
      if (response.data.error_msg === 200) {
        setShowApiResponse(true);
        setApiResponse({
          title: "Sukces",
          severity: "success",
          info: "Użytkownik dodany!",
        });
      }
      //console.log(response.data);
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (userData && userData.grpAdmin) {
      if (userData.grpAdmin !== userData.userId) {
        //navigate("/nopermission");
      }
    }
    if (userData == undefined) {
      //navigate("/nopermission");
    }
  }, [userData]);

  useEffect(() => {
    if (error === 501) {
      setErrorText("Taki użytkownik już istnieje");
    }
    if (error === 200) {
      setErrorText("Dane użytkownika przekazane do zapisu");
      navigate(0);
    }
  }, [error]);

  useEffect(() => {
    const fetchData = async () => {
      const url = API_URL + `/users/${userData.cookie}`;
      try {
        const response = await axios.get(url);
        setData(response.data);
        //const userWithId = response.data.find(
        //  (userData) => userData.id === user
        //);
        //setUserUserData(userWithId);
      } catch (error) {
        setError(error.message);
      }
    };

    if (userData && userData.userId) {
      fetchData();
      const intervalId = setInterval(fetchData, 100000);
      return () => clearInterval(intervalId);
    }
  }, [userData]);

  return (
    <div>
      {showApiResponse && (
        <Row style={{ marginBottom }}>
          <Alerts data={apiResponse} />
        </Row>
      )}
      <h2>Rejestracja Agenta</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <FormikForm>
            <Row
              style={{ marginBottom: "16px" }}
              className="align-items-center"
            >
              <Col md="4" sm="12">
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formUser">
                    <Field
                      as={TextField}
                      label="Konto logowania Agenta"
                      variant="outlined"
                      name="user"
                      autoComplete="off"
                      fullWidth
                    />
                    <ErrorMessage
                      name="user"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
              <Col md="4" sm="12">
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formPassword">
                    <Field
                      as={TextField}
                      label="Nowe hasło"
                      type="password"
                      variant="outlined"
                      name="password"
                      fullWidth
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
              <Col md="4" sm="12">
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formPasswordRepeat">
                    <Field
                      as={TextField}
                      label="Powtórz hasło"
                      type="password"
                      name="repeatpassword"
                      fullWidth
                    />
                    <ErrorMessage
                      name="repeatpassword"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
            </Row>

            <Row style={{ marginBottom }}>
              <Col md="4" sm="12">
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formFullName">
                    <Field
                      as={TextField}
                      label="Imię i nazwisko użytkownika"
                      type="text"
                      name="fullName"
                      fullWidth
                    />
                    <ErrorMessage
                      name="fullName"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
              <Col md="4" sm="12">
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formEmail">
                    <Field
                      as={TextField}
                      label="Email"
                      type="email"
                      name="email"
                      fullWidth
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
              <Col md="4" sm="12">
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formMobile">
                    <Field
                      as={TextField}
                      label="Telefon"
                      type="text"
                      name="mobile"
                      fullWidth
                    />
                    <ErrorMessage
                      name="mobile"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
            </Row>

            <Row style={{ marginBottom }}>
              <Col>
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formDate">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={pl}
                    >
                      <DatePicker
                        label="Wybierz datę ważności konta"
                        selected={values.selectedDate}
                        onChange={(date) => setFieldValue("selectedDate", date)}
                        minDate={addDays(new Date(), 1)}
                        dateFormat="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        slotProps={{ textField: { variant: "outlined" } }}
                      />
                    </LocalizationProvider>
                    <ErrorMessage
                      name="selectedDate"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
              <Col>
                <ThemeProvider theme={theme}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Rejestruj
                  </Button>
                </ThemeProvider>
              </Col>
            </Row>
          </FormikForm>
        )}
      </Formik>

      {errorText}
    </div>
  );
}
