import { useState, useContext, useEffect } from "react";
import { UserDataContext } from "../components/UserDataContext";
import VN from "../components/VN";
import { useParams } from "react-router-dom";
import "../components/button.css";
import TabContent from "../components/TabContent";
import TabContentAgents from "../components/TabContentAgents";

export default function MainPage({ user }) {
  const [value, setValue] = useState(0);

  const { id } = useParams();
  const { userData } = useContext(UserDataContext);

  /*
  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab) {
      setValue(parseInt(savedTab, 10));
    }
  }, []);
*/
  const handleChange = (event, newValue) => {
    setValue(newValue);
    //localStorage.setItem("selectedTab", newValue);
  };

  return (
    <div>
      <VN userData={userData} />
      {userData && userData.grpAdmin == 1 && (
        <TabContent value={value} handleChange={handleChange} />
      )}
      {userData && userData.grpAdmin == 0 && (
        <TabContentAgents value={value} handleChange={handleChange} />
      )}
    </div>
  );
}
