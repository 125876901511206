import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/pl";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { API_URL } from "../App";
import { UserDataContext } from "../components/UserDataContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Checkbox, FormControlLabel } from "@mui/material";
import { pl } from "date-fns/locale";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import axios from "axios";
import { marginBottom } from "../App";
import { Autocomplete, TextField } from "@mui/material";

moment.locale("pl");
const localizer = momentLocalizer(moment);

export default function ReactBigCalendar({ id, data }) {
  const { userData } = useContext(UserDataContext);
  const [eventsData, setEventsData] = useState();
  const [event, setEvent] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showList, setShowList] = useState(false);
  const [insert, setInsert] = useState(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedActors, setSelectedActors] = useState([]);
  const [apiResponse, setApiResponse] = useState({});
  const [showApiResponse, setShowApiResponse] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let url;
      if (data == "all") {
        url = `${API_URL}/getevents/${userData.cookie}/0`;
        setShowList(true);
      } else {
        url = `${API_URL}/getevents/${userData.cookie}/${id}`;
        setShowList(true);
      }
      try {
        const response = await axios.get(url);
        if (Array.isArray(response.data)) {
          const transformedData = response.data.map((event) => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end),
          }));
          setEventsData(transformedData);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, [userData]);

  const handleEvent = (event) => {
    setEvent(event);
    setInsert(false);
    setShowEditModal(true);
  };

  const handleSelect = ({ start, end }) => {
    setEvent({});
    setInsert(true);
    setShowEditModal(true);
  };

  useEffect(() => {
    if (userData) {
      axios
        .get(API_URL + `/getactors/${userData.cookie}`)
        .then((response) => {
          setOptions(response.data);
        })
        .catch((error) => {
          console.error("Błąd w pobieraniu danych:", error);
        });
    }
  }, [userData, inputValue, selectedActors]);

  useEffect(() => {
    if (userData) {
      axios
        .get(API_URL + `/get5calendar/${userData.cookie}`)
        .then((response) => {
          setSelectedActors(response.data);
        })
        .catch((error) => {
          console.error("Błąd w pobieraniu danych:", error);
        });
    }
  }, [userData, inputValue, selectedOption]);

  const actorColors = {
    1: "#CCF1FF",
    2: "#E0D7FF",
    3: "#FFCCE1",
    4: "#957DAD",
    5: "#FFDFD3",
  };

  const actorColorsRef = useRef({}); // Trwała mapa kolorów

  const generatePastelColor = () => {
    const r = Math.floor(Math.random() * 127 + 128); // Jasne kolory 128-255
    const g = Math.floor(Math.random() * 127 + 128);
    const b = Math.floor(Math.random() * 127 + 128);
    return `rgb(${r}, ${g}, ${b})`;
  };

  const getActorColor = (actorId) => {
    if (!actorColorsRef.current[actorId]) {
      actorColorsRef.current[actorId] = generatePastelColor();
    }
    return actorColorsRef.current[actorId];
  };

  const eventPropGetter = (event) => {
    const color = getActorColor(event.actorId);
    return {
      style: {
        backgroundColor: color,
        color: "#000",
        borderRadius: "5px",
        border: "none",
      },
    };
  };

  function transformEvent(event) {
    const formatDateTime = (date) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, "0"); // Miesiące są liczone od 0
      const day = String(d.getDate()).padStart(2, "0");
      const hours = String(d.getHours()).padStart(2, "0");
      const minutes = String(d.getMinutes()).padStart(2, "0");
      const seconds = String(d.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    return {
      ...event,
      start: formatDateTime(event.start),
      end: formatDateTime(event.end),
    };
  }

  const saveEvent = async (event) => {
    if (!insert) {
      // console.log("SE: ", event);
      try {
        const response = await axios.put(
          `${API_URL}/updateevent/${userData.cookie}`,
          event
        );
        // console.log(response);
      } catch (error) {
        console.error("There was an error editing the user calendar!", error);
      }
    } else {
      // console.log("SE insert: ", event);
      try {
        const response = await axios.post(
          `${API_URL}/insertevent/${userData.cookie}/${id}`,
          event
        );
        //console.log(response);
      } catch (error) {
        console.error("There was an error inserting the user calendar!", error);
      }
    }
  };

  const handleAddActor = (actor) => {
    if (
      selectedActors.length < 5 &&
      !selectedActors.some((a) => a.id === actor.idActor)
    ) {
      setSelectedActors([...selectedActors, actor]);
    } else if (selectedActors.some((a) => a.id === actor.idActor)) {
      setApiResponse({
        title: "Błąd",
        severity: "error",
        info: "Ten aktor już został dodany.",
      });
      setShowApiResponse(true);
    } else {
      setApiResponse({
        title: "Błąd",
        severity: "warning",
        info: "Możesz dodać maksymalnie 5 aktorów.",
      });
      setShowApiResponse(true);
    }
  };

  const handleRemoveActor = (idActor) => {
    async function deleteEvent() {
      try {
        const response = await fetch(
          `${API_URL}/deleteeventview/${userData.cookie}/${idActor}`,
          {
            method: "DELETE",
          }
        );

        const data = await response.json();
        if (data.rowsDeleted === 1) {
          setSelectedActors((prevActors) =>
            prevActors.filter((actor) => actor.idActor !== idActor)
          );
        } else {
          console.error("Nie udało się usunąć aktora");
        }
      } catch (error) {
        console.error("Wystąpił błąd podczas usuwania aktora:", error);
      }
    }
    deleteEvent();
  };

  const handleSaveView = (idActor) => {
    async function insertEvent() {
      try {
        const response = await fetch(
          `${API_URL}/inserteventview/${userData.cookie}/${idActor}`,
          {
            method: "POST",
          }
        );
        const data = await response.json();
        if (data.lastInsertId > 0) {
          setSelectedActors((prevActors) =>
            prevActors.filter((actor) => actor.idActor !== idActor)
          );
        } else {
          console.error("Nie udało się dodać aktora");
        }
      } catch (error) {
        console.error("Wystąpił błąd podczas dodawania aktora:", error);
      }
    }
    insertEvent();
  };

  const handleDeleteEvent = (event) => {
    //console.log("delete: ", event.id);
  };

  return (
    <div className="App">
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {insert ? "Dodaj " : "Edytuj"} [ ID: ] {event.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Treść </Form.Label>
              <Form.Control
                type="text"
                value={event.title || ""}
                onChange={(e) => setEvent({ ...event, title: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={pl}
              >
                <Row style={{ marginBottom }}>
                  <Col>
                    <DatePicker
                      label="Od:"
                      value={event.start ? new Date(event.start) : null}
                      onChange={(newValue) => {
                        setEvent({
                          ...event,
                          start: newValue
                            ? new Date(newValue).toISOString()
                            : null,
                        });
                      }}
                      format="yyyy-MM-dd"
                      slotProps={{ textField: { variant: "outlined" } }}
                    />
                    <TimePicker
                      label="Czas od:"
                      value={event.start ? new Date(event.start) : null}
                      onChange={(newValue) => {
                        const updatedStart = new Date(
                          event.start || new Date()
                        );
                        updatedStart.setHours(newValue.getHours());
                        updatedStart.setMinutes(newValue.getMinutes());
                        setEvent({
                          ...event,
                          start: updatedStart,
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <DatePicker
                      label="Do:"
                      value={event.end ? new Date(event.end) : null}
                      onChange={(newValue) => {
                        setEvent({
                          ...event,
                          end: newValue
                            ? new Date(newValue).toISOString()
                            : null,
                        });
                      }}
                      format="yyyy-MM-dd"
                      slotProps={{ textField: { variant: "outlined" } }}
                    />

                    <TimePicker
                      label="Czas do:"
                      value={event.end ? new Date(event.end) : null}
                      onChange={(newValue) => {
                        const updatedEnd = new Date(event.end || new Date());
                        updatedEnd.setHours(newValue.getHours());
                        updatedEnd.setMinutes(newValue.getMinutes());
                        setEvent({
                          ...event,
                          end: updatedEnd,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </LocalizationProvider>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col md={3}>
              {userData && userData.grpAdmin == 0 && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDeleteEvent(event)}
                >
                  Skasuj plan
                </Button>
              )}
            </Col>
            <Col>
              <Button variant="outlined" onClick={handleCloseEditModal}>
                Anuluj
              </Button>
            </Col>
            <Col>
              {userData && userData.grpAdmin == 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const transformedEvent = transformEvent(event);
                    saveEvent(transformedEvent);
                    handleCloseEditModal();
                  }}
                >
                  Zapisz
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      {showList && (
        <Row style={{ marginBottom }}>
          <Col md={3}>
            <Autocomplete
              value={selectedOption}
              onChange={(event, newValue) => {
                if (newValue) handleAddActor(newValue);
                setSelectedOption(null);
                handleSaveView(newValue.id);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) =>
                setInputValue(newInputValue)
              }
              options={options}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                return option.name ? option.name : "";
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Wyszukaj aktora"
                  variant="outlined"
                />
              )}
            />
          </Col>

          <Col>
            <div>
              {selectedActors.map((actor, index) => (
                <div key={actor.idActor || index}>
                  <Row>
                    <Col md={3}>{actor.name}</Col>
                    <Col md={1}>
                      <Button
                        variant="outlined"
                        onClick={() => handleRemoveActor(actor.idActor)}
                      >
                        Usuń
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      )}
      <Calendar
        views={["day", "agenda", "week", "month"]}
        selectable
        localizer={localizer}
        culture="pl"
        defaultDate={new Date()}
        defaultView="month"
        events={eventsData}
        style={{ height: "500px" }}
        onSelectEvent={(event) => handleEvent(event)}
        onSelectSlot={handleSelect}
        eventPropGetter={eventPropGetter}
        messages={{
          next: "Następny",
          previous: "Poprzedni",
          today: "Dzisiaj",
          month: "Miesiąc",
          week: "Tydzień",
          day: "Dzień",
          agenda: "Agenda",
          date: "Data",
          time: "Czas",
          event: "Wydarzenie",
          noEventsInRange: "Brak wydarzeń w tym zakresie.",
        }}
      />
    </div>
  );
}
