import { Table, Row, Col } from "react-bootstrap";
import { API_URL } from "../App";
import { UserDataContext } from "../components/UserDataContext";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddActor from "./AddActor";
import "../components/button.css";
import Button from "@mui/material/Button";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import { marginBottom } from "../App";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../components/theme";

export default function DataActors({ group }) {
  const { userData } = useContext(UserDataContext);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const navigate = useNavigate();
  const [showRegister, setShowRegister] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  useEffect(() => {
    const fetchData = async () => {
      const url = API_URL + `/getactors/${userData.cookie}`;
      try {
        const response = await axios.get(url);
        if (Array.isArray(response.data)) {
          setData(response.data);
        } else {
          setData([]);
        }
        //console.log(response.data);
      } catch (error) {
        setError(error.message);
        setData([]);
      }
    };
    if (userData && userData.userId) {
      fetchData();
      const intervalId = setInterval(fetchData, 10000);
      return () => clearInterval(intervalId);
    }
  }, [userData, group]);

  const handleEditActor = (id) => {
    setSelectedId(id);
    setShowRegister(false);
    navigate(`/editactorstabs/${id}`);
  };
  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.key === null) return 0;
    const direction = sortConfig.direction === "asc" ? 1 : -1;

    if (a[sortConfig.key] < b[sortConfig.key]) {
      return -1 * direction;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return 1 * direction;
    }
    return 0;
  });
  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleAddActor = () => {
    setShowRegister(!showRegister);
    setShowEdit(false);
    setSelectedId(0);
  };

  return (
    <div>
      {showRegister && <AddActor group={group.id} />}
      <Row style={{ marginBottom }}>
        <Col xs={4} sm={3}>
          {userData && userData.agent == 1 && (
            <ThemeProvider theme={theme}>
              <Button variant="outlined" onClick={handleAddActor}>
                <TheaterComedyIcon /> Dodaj Aktora
              </Button>
            </ThemeProvider>
          )}
        </Col>
        <Col xs={4} sm={3}>
          <h2>Lista Aktorów</h2>
        </Col>
      </Row>

      <div className="table-responsive">
        <Table bordered hover>
          <thead>
            <tr>
              <th className="td-centered" onClick={() => requestSort("id")}>
                ID
              </th>
              <th onClick={() => requestSort("name")}>Aktor</th>
              <th onClick={() => requestSort("filmpolski")}>filmpolski.pl</th>
              <th onClick={() => requestSort("phone")}>Telefon</th>
              <th onClick={() => requestSort("email")}>Email</th>
              <th onClick={() => requestSort("description")}>Informacja</th>
              <th onClick={() => requestSort("fullName")}>Agent</th>
              <th className="td-centered">Edytuj</th>
            </tr>
          </thead>

          <tbody>
            {sortedData.map((item, index) => (
              <tr key={index}>
                <td className="td-centered">{index + 1}</td>
                <td>
                  {item.name} [{item.id}]
                </td>
                <td>
                  <a
                    href={item.filmpolski}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.filmpolski}
                  </a>
                </td>
                <td className="td-centered">{item.phone}</td>
                <td className="td-centered">{item.email}</td>
                <td className="td-centered">{item.description}</td>
                <td className="td-centered">{item.fullname}</td>
                <td className="td-centered">
                  <ThemeProvider theme={theme}>
                    <Button
                      variant="outlined"
                      onClick={() => handleEditActor(item.id)}
                    >
                      <i className="fa fa-edit" aria-hidden="true"></i>
                    </Button>
                  </ThemeProvider>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
