import { useState, useContext } from "react";
import { UserDataContext } from "../components/UserDataContext";
import VN from "../components/VN";
import EditActor from "./EditActor";
import { useParams } from "react-router-dom";
import "../components/button.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../components/theme";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function EditActorsTabs({ user }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { id } = useParams();
  const { userData } = useContext(UserDataContext);

  return (
    <div>
      <VN userData={userData} />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <ThemeProvider theme={theme}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="underMenu"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab label="Edycja Aktora" />
            </Tabs>
          </ThemeProvider>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <EditActor />
        </CustomTabPanel>
      </Box>
    </div>
  );
}
