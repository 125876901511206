import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { UserProvider } from "./components/UserContext";
import { UserDataProvider } from "./components/UserDataContext";
import { SearchProvider } from "./components/SearchContext";
import Login from "./pages/Login";
import NoPermission from "./pages/NoPermission";
import AddGroupOwner from "./pages/AddGroupOwner";
import PassResetMail from "./pages/PassResetMail";
import MainPage from "./pages/MainPage";
import Agents from "./pages/Agents";
import Actors from "./pages/Actors";
import Groups from "./pages/Groups";
import PassChange from "./pages/PassChange";
import EditAgentTabs from "./pages/EditAgentTabs";
import EditActorsTabs from "./pages/EditActorsTabs";
import Calendar from "./pages/Calendar";

export const API_URL = "http://185.180.205.154/mastermovie/api";
export const APP_NAME = "MASTERMOVIE";
export const cookie = "mastermovie";
export const windowWidth = 768;
export const marginBottom = "2%";
export const fontFamily = "Wix Madefor Display";

//const basename = process.env.NODE_ENV === "production" ? "/mastermovie" : "/";

function App() {
  return (
    <>
      <UserDataProvider>
        <UserProvider>
          <SearchProvider>
            {/* <BrowserRouter basename={basename}> */}
            <BrowserRouter basename="/mastermovie">
              <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/nopermission" element={<NoPermission />} />
                <Route path="/addgroupowner" element={<AddGroupOwner />} />
                <Route path="/passresetmail" element={<PassResetMail />} />
                <Route path="/passchange" element={<PassChange />} />
                <Route path="/mainpage/:id" element={<MainPage />} />
                <Route path="/edittabs/:id" element={<EditAgentTabs />} />
                <Route
                  path="/editactorstabs/:id"
                  element={<EditActorsTabs />}
                />
                <Route path="/agents" element={<Agents />} />
                <Route path="/actors" element={<Actors />} />
                <Route path="/groups" element={<Groups />} />
                <Route path="/calendar" element={<Calendar />} />
              </Routes>
            </BrowserRouter>
          </SearchProvider>
        </UserProvider>
      </UserDataProvider>
    </>
  );
}

export default App;
