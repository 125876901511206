import { useState, useEffect, useContext } from "react";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import { Form, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../App";
import { UserDataContext } from "../components/UserDataContext";
import { registerLocale } from "react-datepicker";
import { useParams } from "react-router-dom";
import "../components/button.css";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Calendar from "./Calendar";
import { ThemeProvider } from "@mui/material/styles";
import Alerts from "../components/Alerts";
import { marginBottom } from "../App";

import { pl } from "date-fns/locale";
import { theme } from "../components/theme";

const validationSchema = (idgrpAdmin) =>
  Yup.object().shape({
    user: Yup.string().required("Użytkownik jest wymagany"),
    fullname: Yup.string().required("Imię i nazwisko są wymagane"),
    filmpolski: Yup.string().required("Adres do strony FilmPolski wymagany!"),
    phone: Yup.string().matches(/^\d+$/, "Telefon musi zawierać tylko cyfry"),
  });
registerLocale("pl", pl);

export default function EditActor() {
  const { id } = useParams();
  const { userData } = useContext(UserDataContext);
  const [userUserData, setUserUserData] = useState({});
  const [initialFormValues, setInitialFormValues] = useState({
    user: "",
    fullname: "",
    email: "",
    filmpolski: "",
    phone: "",
    description: "",
  });
  const navigate = useNavigate();
  //console.log("idActor: ", id);
  const [apiResponse, setApiResponse] = useState({});
  const [idgrpAdmin, setIdgrpAdmin] = useState({});
  const [checked, setChecked] = useState(false);
  const [showApiResponse, setShowApiResponse] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const ClearApiResponse = () => {
    setShowApiResponse(false);
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    values["idgrp"] = userData.idgrp;
    values["id"] = id;
    try {
      //console.log("Values: ", values);
      const response = await axios.put(
        API_URL + `/editactor/${userData.cookie}`,
        values
      );
      // console.log("response: ", response);
      setShowApiResponse(true);
      setApiResponse({
        title: "Informacja",
        severity: "info",
        info: "Dane zaktualizowane!",
      });
    } catch (error) {
      setShowApiResponse(true);
      setApiResponse({
        title: "Uwaga",
        severity: "error",
        info: "Nie udało się zaktualizować!",
      });
      console.error("There was an error editing the user!", error);
    } finally {
      setSubmitting(false);
    }
  };
  const handleCalendar = () => setShowCalendar(!showCalendar);

  const handleCheckbox = async (event) => {
    setChecked(event.target.checked);
    let activity;
    if (event.target.checked === true) {
      activity = 1;
    }
    if (event.target.checked === false) {
      activity = 0;
    }
    const url = API_URL + `/activity/${userData.cookie}/${id}`;

    try {
      const response = await axios.put(
        url,
        { activity: activity },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setShowApiResponse(true);
      setApiResponse({
        title: "Informacja",
        severity: "info",
        info: "Aktywność aktora zaktualizowana!",
      });
    } catch (error) {
      console.error("Błąd podczas próby zablokowania użytkownika:", error);
    }
  };
  useEffect(() => {
    //console.log("UD: ", userData);
    if (userData && userData.grpAdmin) {
      if (userData.grpAdmin !== "1") {
        //navigate("/nopermission");
      }
    }
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${API_URL}/actor/${userData.cookie}/${id}`;
      try {
        const response = await axios.get(url);
        //if (response.data) {
        if (response.data[0].is_active === "1") {
          setChecked(true);
        }

        //console.log("RD: ", response.data);
        if (response.data) {
          setUserUserData(response.data);
          setIdgrpAdmin({ idgrpAdmin: response.data[0].idgrpAdmin });
          setInitialFormValues({
            user: response.data[0].name || "",
            fullname: response.data[0].fullname || "",
            email: response.data[0].email || "",
            filmpolski: response.data[0].filmpolski || "",
            phone: response.data[0].phone || "",
            description: response.data[0].description || "",
            agentId: response.data[0].agentId || "",
          });
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    if (userData && userData.userId) {
      fetchData();
      const intervalId = setInterval(fetchData, 100000);
      return () => clearInterval(intervalId);
    }
  }, [userData]);

  return (
    <div>
      {showApiResponse && (
        <Row style={{ marginBottom }}>
          <Alerts data={apiResponse} />
        </Row>
      )}
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, setValues, values, handleChange }) => (
          <FormikForm>
            <Row
              style={{ marginBottom: "16px" }}
              className="align-items-center"
            >
              <Col md={4}>
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="name">
                    <TextField
                      name="user"
                      label="Aktor"
                      variant="outlined"
                      value={values.user}
                      onChange={(e) => {
                        handleChange(e);
                        ClearApiResponse();
                      }}
                      fullWidth
                      inputProps={{ readOnly: true }}
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
              <Col md={4}>
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formFullName">
                    <TextField
                      name="fullname"
                      label="Agent"
                      variant="outlined"
                      value={values.fullname}
                      onChange={(e) => {
                        handleChange(e);
                        ClearApiResponse();
                      }}
                      fullWidth
                      inputProps={{ readOnly: true }}
                    />
                    <ErrorMessage
                      name="fullname"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
              <Col md={4}>
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formEmail">
                    <TextField
                      name="email"
                      label="E-mail"
                      variant="outlined"
                      value={values.email}
                      onChange={(e) => {
                        handleChange(e);
                        ClearApiResponse();
                      }}
                      fullWidth
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
            </Row>

            <Row style={{ marginBottom }}>
              <Col md={4}>
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formFilmpolski">
                    <TextField
                      name="filmpolski"
                      label="filmpolski"
                      variant="outlined"
                      value={values.filmpolski}
                      onChange={(e) => {
                        handleChange(e);
                        ClearApiResponse();
                      }}
                      fullWidth
                    />
                    <ErrorMessage
                      name="filmpolski"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
              <Col md={4}>
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formPhone">
                    <TextField
                      name="phone"
                      label="Telefon"
                      variant="outlined"
                      value={values.phone}
                      onChange={(e) => {
                        handleChange(e);
                        ClearApiResponse();
                      }}
                      fullWidth
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
              <Col md={4}>
                <ThemeProvider theme={theme}>
                  <Form.Group controlId="formDescription">
                    <TextField
                      name="description"
                      label="Opis"
                      variant="outlined"
                      value={values.description}
                      onChange={(e) => {
                        handleChange(e);
                        ClearApiResponse();
                      }}
                      fullWidth
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </ThemeProvider>
              </Col>
            </Row>
            <Row className="align-items-center">
              {userData && userData.agent == 1 && (
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={checked} onChange={handleCheckbox} />
                    }
                    label="Aktor aktywny?"
                  />
                </Col>
              )}

              <Col md={3} className="d-flex align-items-center">
                <Button
                  variant="outlined"
                  onClick={handleCalendar}
                  //type="submit"
                  //disabled={isSubmitting}
                >
                  <CalendarMonthIcon />
                  Kalendarz aktora
                </Button>
              </Col>
              <Col md={3} className="d-flex align-items-center">
                {userData && userData.agent == 1 && (
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <EditSharpIcon />
                    Zapisz
                  </Button>
                )}
              </Col>
            </Row>
          </FormikForm>
        )}
      </Formik>
      {showCalendar && <Calendar id={id} />}
    </div>
  );
}
